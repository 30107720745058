import Icon from 'components/Icon'
import Image from 'components/Image'
import InvisibleButton from 'components/InvisibleButton'
import Link from 'components/Link'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { useToggle } from 'hooks/useToggle'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { authStateSelector } from 'store/auth/selector'
import theme from 'styles/theme'

const LinkItem = ({ link, hasNavigation, ...rest }) => (
  <li css={{ display: 'flex' }}>
    <Link
      to={hasNavigation ? '' : link.url}
      className={link.linkClass}
      css={{
        display: 'flex',
        alignSelf: 'center',
        alignItems: 'center',
        padding: '0 0.5rem',
        whiteSpace: 'nowrap',
        height: '100%',
        background: `linear-gradient(to bottom, ${theme.colors.broccoli} 0%, ${theme.colors.broccoli} 100%)`,
        backgroundPosition: '0 100%',
        backgroundRepeat: 'repeat-x',
        backgroundSize: '4px 0',
        color: theme.colors.spinachDark,
        textDecoration: 'none',
        transition: 'background-size .2s',
        cursor: 'pointer',
        outline: 'none',
        border: 'none',
        ':hover': {
          color: theme.colors.spinach,
          backgroundSize: '4px 4px',
        },
        ':focus': {
          outline: 'none',
        },
      }}
      {...rest}
    >
      {link.text}
      {hasNavigation && (
        <Icon.DropdownArrow width={24} css={{ color: 'inherit' }} aria-hidden='true' />
      )}
    </Link>
  </li>
)

const DesktopNav = ({
  header: { mainLinks, dropdownTitleLink, dropdownLinks, userAccountLink },
}) => {
  const {
    isToggled: productsOpen,
    toggle: toggleProductsOpen,
    setToggle: setProductsOpen,
  } = useToggle(false)
  const [selectedDropdown, setSelectedDropdown] = useState(false)
  const [selectedNavigation, setSelectedNavigation] = useState([])
  const dropdownRef = useRef()
  const shopRef = useRef()
  const rightMenuRef = useRef()
  const megaMenuRef = useRef()
  useOnClickOutside([dropdownRef, shopRef], () => setProductsOpen(false), productsOpen)
  useOnClickOutside(
    [megaMenuRef, rightMenuRef],
    () => {
      setSelectedDropdown(false)
      setSelectedNavigation(undefined)
    },
    selectedDropdown
  )

  const authState = useSelector(authStateSelector)

  const onNavClicked = nav => {
    if (selectedDropdown === nav.link.text) {
      setSelectedNavigation(false)
      setSelectedDropdown(undefined)
    } else {
      setSelectedNavigation(nav)
      setSelectedDropdown(nav.link.text)
    }
  }

  return (
    <nav
      className='DesktopNav'
      css={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        [theme.headerBreakpoint.small]: {
          display: 'none',
        },
      }}
    >
      <div
        css={{
          display: 'flex',
          [theme.medium]: {
            marginLeft: '2rem',
          },
          [theme.large]: {
            marginLeft: '4rem',
          },
        }}
      >
        <InvisibleButton
          ref={shopRef}
          onClick={toggleProductsOpen}
          css={{
            textDecoration: 'none',
            padding: '0 0.5rem',
            display: 'flex',
            alignSelf: 'stretch',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            height: '100%',
            background: `linear-gradient(to bottom, ${theme.colors.broccoli} 0%, ${theme.colors.broccoli} 100%)`,
            backgroundPosition: '0 100%',
            backgroundRepeat: 'repeat-x',
            backgroundSize: '4px 0',
            color: theme.colors.spinachDark,
            transition: 'background-size .2s',
            ':hover': {
              color: theme.colors.spinach,
              backgroundSize: '4px 4px',
            },
            ':focus': {
              outline: 'none',
            },
          }}
        >
          {dropdownTitleLink.text}
          <Icon.DropdownArrow width={24} css={{ color: 'inherit' }} aria-hidden='true' />
        </InvisibleButton>
        <ul
          css={{
            margin: 0,
            padding: 0,
            listStyle: 'none',
            display: 'flex',
          }}
        >
          {mainLinks
            .filter(link => link.navPosition === 'Left')
            .map((link, i) => (
              <LinkItem
                key={i}
                link={link.link}
                hasNavigation={link.navigationGroup.length > 0}
                onClick={link.navigationGroup.length > 0 ? () => onNavClicked(link) : null}
              />
            ))}
        </ul>
      </div>
      <div
        ref={megaMenuRef}
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute',
          visibility: selectedDropdown ? 'visible' : 'hidden',
          opacity: selectedDropdown ? 1 : 0,
          top: 'calc(100% + 1px)',
          left: 0,
          right: 0,
          zIndex: theme.zIndex.navigation,
          backgroundColor: theme.colors.miscBackground,
          borderRadius: '0 0 3px 3px',
          boxShadow: theme.boxShadow.deep,
          transition: '.2s ease-out all',
        }}
      >
        <ul
          css={{
            display: 'flex',
            width: '100%',
            padding: '3rem 2rem',
            margin: 0,
            listStyle: 'none',
          }}
        >
          {selectedNavigation &&
            selectedNavigation.navigationGroup &&
            selectedNavigation.navigationGroup.map((navGroup, i) => {
              return (
                <li
                  key={i}
                  css={{
                    width: '20%',
                  }}
                >
                  <ul
                    css={{
                      padding: 0,
                      listStyle: 'none',
                    }}
                  >
                    {navGroup.links.map((link, i) => (
                      <li
                        key={i}
                        css={{
                          marginTop: '1rem',
                          paddingLeft: '1rem',
                          ':first-child': {
                            ...theme.h5,
                            fontWeight: theme.fontWeight.bold,
                            marginTop: 0,
                            paddingLeft: 0,
                            button: {
                              fontWeight: theme.fontWeight.bold,
                            },
                          },
                        }}
                      >
                        <Link
                          to={link.url}
                          css={{
                            textAlign: 'left',
                            cursor: 'pointer',
                            color: theme.colors.spinachDark,
                            backgroundColor: 'transparent',
                            border: 'none',
                            outline: 'none',
                            padding: 0,
                          }}
                          onClick={() => {
                            setSelectedDropdown(false)
                          }}
                        >
                          {link.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              )
            })}
        </ul>
        {selectedNavigation && selectedNavigation.ctaButtonText && (
          <Link
            to={selectedNavigation.link.url}
            onClick={() => {
              setSelectedDropdown(false)
            }}
            theme='button'
            className={dropdownTitleLink.linkClass}
            css={{
              margin: 0,
              borderRadius: 0,
              ':hover': { backgroundColor: theme.colors.spinach },
            }}
          >
            {selectedNavigation.ctaButtonText}
          </Link>
        )}
      </div>
      <div
        ref={dropdownRef}
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute',
          visibility: productsOpen ? 'visible' : 'hidden',
          opacity: productsOpen ? 1 : 0,
          top: 'calc(100% + 1px)',
          left: 0,
          right: 0,
          zIndex: theme.zIndex.navigation,
          backgroundColor: theme.colors.miscBackground,
          borderRadius: '0 0 3px 3px',
          boxShadow: theme.boxShadow.deep,
          transition: '.2s ease-out all',
        }}
      >
        <ul
          css={{
            listStyle: 'none',
            margin: 0,
            padding: '2rem',
            display: 'grid',
            gridTemplateColumns: `1fr 1fr 1fr 1fr ${dropdownLinks.length % 2 === 0 ? '' : '1fr'}`,
            [theme.mediumOnly]: {
              gridTemplateColumns: '1fr 1fr',
            },
            gap: '1rem',
          }}
        >
          {dropdownLinks.map((link, i) => (
            <li key={i}>
              <Link
                to={link.url}
                onClick={() => {
                  setProductsOpen(false)
                }}
                css={{ display: 'block' }}
              >
                <Image
                  {...link.image}
                  width={375}
                  height={320}
                  alt={link.title}
                  css={{ borderRadius: '3px' }}
                />
                <span
                  css={{
                    ...theme.h5,
                    marginTop: '.75rem',
                    display: 'block',
                    textAlign: 'center',
                  }}
                >
                  {link.title}
                </span>
              </Link>
            </li>
          ))}
        </ul>
        <Link
          to={dropdownTitleLink.url}
          onClick={() => {
            setProductsOpen(false)
          }}
          theme='button'
          className={dropdownTitleLink.linkClass}
          css={{
            margin: '1rem 0 0',
            borderRadius: 0,
            ':hover': { backgroundColor: theme.colors.spinach },
          }}
        >
          All OWYN® Products →
        </Link>
      </div>

      <ul
        className='DesktopNav'
        ref={rightMenuRef}
        css={{
          margin: 0,
          padding: 0,
          listStyle: 'none',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {mainLinks
          .filter(link => link.navPosition === 'Right')
          .map((link, i) => (
            <LinkItem
              key={i}
              link={link.link}
              hasNavigation={link.navigationGroup.length > 0}
              onClick={link.navigationGroup.length > 0 ? () => onNavClicked(link) : null}
            />
          ))}
        <li>
          <Link
            to={authState.currentUser ? '/my-account' : '/login'}
            target='_self'
            css={{
              ...theme.h4,
              textDecoration: 'none',
              color: theme.colors.spinachDark,
              display: 'inline-block',
              width: '3rem',
              height: '3rem',
              lineHeight: '3rem',
              textAlign: 'center',
              borderRadius: '100%',
              margin: '.5rem',
              padding: '.5em',
              transition: '150ms linear all',
              position: 'relative',
              ':hover': {
                backgroundColor: theme.colors.broccoliLight,
              },
            }}
          >
            <Icon.PersonOutline
              width={24}
              height={24}
              css={{ color: theme.colors.spinachDark }}
              aria-hidden='true'
            />
            <span className='sr-only'>Login</span>
          </Link>
        </li>
      </ul>
    </nav>
  )
}

const linkType = PropTypes.shape({
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  linkClass: PropTypes.string,
})

const headerNavType = PropTypes.shape({
  link: linkType,
  navPosition: PropTypes.string.isRequired,
  ctaButtonText: PropTypes.string,
  navigationGroup: PropTypes.arrayOf(
    PropTypes.shape({
      links: PropTypes.arrayOf(linkType),
    })
  ),
})

const productLinkType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.object,
})

const headerType = PropTypes.shape({
  mainLinks: PropTypes.arrayOf(headerNavType).isRequired,
  dropdownTitleLink: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    linkClass: PropTypes.string,
  }),
  dropdownLinks: PropTypes.arrayOf(productLinkType).isRequired,
  userAccountLink: PropTypes.string.isRequired,
})

DesktopNav.propTypes = { header: headerType }

export default DesktopNav
